<template>
  <cks-page-wrap v-loading="loading" style="padding-bottom: 100px;">
    <cks-edit-form
      @updatePosi="updatePosi"
      :isPosi="true"
      title="加入我们编辑"
      @save="save"
      ref="formRef"
      label-position="top"
      :rules="rules"
      :model="form"
    >
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="工作名称" prop="jobName">
            <el-input v-model="form.jobName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="薪资下限" prop="payDownRange">
            <el-input v-model="form.payDownRange"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="薪资上限" prop="payUpRange">
            <el-input v-model="form.payUpRange"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="工作地点" prop="address">
            <el-input v-model="form.address"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="学历要求" prop="eduBack">
            <el-input v-model="form.eduBack"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="工作年限" prop="workYears">
            <el-input v-model="form.workYears"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="联系电话" prop="phone">
            <el-input v-model="form.phone"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8" v-if="id">
          <el-form-item label="创建日期" prop="createdTime">
            <el-input disabled v-model="form.createdTime"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8" v-if="id">
          <el-form-item label="更新日期" prop="updatedTime">
            <el-input disabled v-model="form.updatedTime"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="jobResponsibilities">
            <template #label>
              <span style="color: red;">*</span> 岗位职责
            </template>
            <!-- <el-input v-model="form.jobResponsibilities"></el-input> -->
            <div v-if="temp" class="posi"></div>
            <quill-editor ref="quillEditorRef" v-model="form.jobResponsibilities" :options="editorOption"></quill-editor>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="requirements">
            <template #label>
              <span style="color: red;">*</span> 任职要求
            </template>
            <!-- <el-input v-model="form.requirements"></el-input> -->
            <div v-if="temp" class="posi"></div>
            <quill-editor ref="quillEditorRef2" v-model="form.requirements" :options="editorOption"></quill-editor>
          </el-form-item>
        </el-col>
      </el-row>
    </cks-edit-form>
  </cks-page-wrap>
</template>

<script>
import {
  toRefs, reactive, onMounted,
} from 'vue';
import {
  showSuccess, getRequireRule, getTwoRule, showError,
} from '@/utils';
import apis from '@/request/apis';
import { useRouter } from 'vue-router';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

export default {
  props: ['id'],
  components: {
    QuillEditor,
  },
  setup(props) {
    const router = useRouter();
    const data = reactive({
      formRef: null,
      form: {
        phone: '199-0267-0462',
      },
      rules: {
        jobName: [getRequireRule('工作名称')],
        payDownRange: [getRequireRule('薪资下限'), getTwoRule()],
        payUpRange: [getRequireRule('薪资上限'), getTwoRule()],
        address: [getRequireRule('工作地点')],
        eduBack: [getRequireRule('学历要求')],
        phone: [getRequireRule('联系电话')],
        workYears: [getRequireRule('工作年限')],
        // jobResponsibilities: [getRequireRule('岗位职责')],
        // requirements: [getRequireRule('任职要求')],
      },
      loading: false,
      temp: false,
      quillEditorRef: null,
      quillEditorRef2: null,
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [
              { list: 'ordered' },
              { list: 'bullet' },
            ],
            [
              // { color: [] },
              { background: [] },
            ],
          ],
        },
      },
    });

    async function save(done) {
      try { await data.formRef.validate(); } catch (e) { return done(false); }
      if (data.quillEditorRef.getHTML() === '<p><br></p>') {
        done(false);
        showError(new Error('岗位职责不能为空!'));
        return;
      }
      if (data.quillEditorRef2.getHTML() === '<p><br></p>') {
        done(false);
        showError(new Error('任职要求不能为空!'));
        return;
      }
      try {
        await apis.joinUs.save({
          ...data.form,
          jobResponsibilities: data.quillEditorRef.getHTML(),
          requirements: data.quillEditorRef2.getHTML(),
        });
        showSuccess('保存成功');
        done();
        router.push({
          name: 'joinUs',
        });
      } catch (e) {
        done(e);
      }
    }

    async function loadDetail() {
      data.loading = true;
      try {
        const res = await apis.joinUs.getDetail({ id: props.id });
        data.form = res;
        if (data.form.jobResponsibilities) {
          data.quillEditorRef.setHTML(data.form.jobResponsibilities);
        }
        if (data.form.requirements) {
          data.quillEditorRef2.setHTML(data.form.requirements);
        }
      } finally {
        data.loading = false;
      }
    }

    onMounted(() => {
      if (props.id) {
        loadDetail();
      } else {
        props.id || data.formRef?.unlock();
      }
    });

    return {
      save,
      ...toRefs(data),
    };
  },
};
</script>

<style lang="scss" scoped>
.el-input {
  --el-input-focus-border: #2F51FF;
}
::v-deep .quill-editor {
  height: 400px;
}
::v-deep .ql-container{
  height: 300px;
}
.posi {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 20px;
  z-index: 999;
  background-color: #f5f7fa;
  opacity: 0.5;
  cursor:not-allowed;
}
</style>
